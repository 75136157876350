export const projects = [
  {
    id: 1,
    title: "Portfolio React",
    description: "Un portfolio personnel construit avec React pour présenter mes projets et compétences.",
    link: "https://nicolasgayet.fr",
    image: "https://via.placeholder.com/300"
  },
  {
    id: 2,
    title: "Projet 1",
    description: "Description du projet 1",
    link: "https://nicolasgayet.fr",
    image: "https://via.placeholder.com/300"
  },
  {
    id: 3,
    title: "Projet 2",
    description: "Description du projet 2",
    link: "https://nicolasgayet.fr",
    image: "https://via.placeholder.com/300"
  }
];