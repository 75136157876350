import React from 'react';

function Home() {
  return (
    <div className="home">
      <h1>Bienvenue sur mon portfolio</h1>
      <p>Découvrez mes projets et compétences !</p>
    </div>
  );
}

export default Home;