import React from 'react';

function About() {
  return (
    <div className="about">
      <h1>À propos de moi</h1>
      <p>
        Je suis Nicolas Gayet, un développeur web avec une solide expérience en développement frontend et backend.
      </p>
      <p>
        Après avoir obtenu mon diplôme en informatique, j'ai travaillé sur plusieurs projets, allant de sites web simples à des applications complexes.J'aime apprendre de nouvelles technologies pour rester à jour dans ce domaine en constante évolution.
      </p>
      <h2>Compétences</h2>
      <ul>
        <li>JavaScript, React, HTML, CSS</li>
        <li>Node.js, Express</li>
        <li>Git, GitLab</li>
        {/* <li>Développement responsive et design UX/UI</li> */}
      </ul>
    </div>
  );
}

export default About;