import React from 'react';

function Contact() {
  return (
    <div className="contact">
      <h1>Contact</h1>
      <p>
        Vous souhaitez collaborer ou avez une question ? N'hésitez pas à me contacter via les informations ci-dessous.
      </p>
      <ul>
        <li><strong>LinkedIn :</strong> <a href="https://www.linkedin.com/in/nicolasgayet" target="_blank" rel="noopener noreferrer">linkedin.com/in/nicolasgayet</a></li>
      </ul>
      <p>Je suis toujours ouvert aux nouvelles opportunités et collaborations. Merci de votre intérêt !</p>
    </div>
  );
}

export default Contact;