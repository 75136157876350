import { NavLink } from 'react-router-dom';

function Navigation() {
  return (
    <nav>
      <NavLink to="/" activeClassName="active">Accueil</NavLink>
      <NavLink to="/about" activeClassName="active">À propos</NavLink>
      <NavLink to="/projects" activeClassName="active">Mes Projets</NavLink>
      <NavLink to="/contact" activeClassName="active">Contact</NavLink>
    </nav>
  );
}

export default Navigation;